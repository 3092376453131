import React from 'react';
import "../Carrers/Carrers.css";
import image5 from "../assets/Group 1925.png";
import img11 from "../assets/Group 258476.png.png";
import Footer from '../Footer/Footer';

const Carrers = () => {
  return (
    <div>
      <section className="about-sectionm  mt-5  ">
        <img src={image5} alt="Icon Left " className="icon-left" />
        <div className="container mt-5 pt-5">
          <h1 className="text-center">Carrers</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ color: "white" }}
              >
                Carrers
              </li>
            </ol>
          </nav>
        </div>
        <img src={img11} alt="Icon Right" className="icon-right" />
      </section>
      <div className="container my-5 mt-5">
        <h2 className="text-center mb-5 mt-5 fs-1 fw-bold">Requirements</h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
            <div className="card job-card">
              <div className="card-body d-flex justify-content-between align-items-center mt-3">
                <div>
                  <h5 className="card-title pt-2">
                    Deputy Manager - Production
                  </h5>
                  <p className="card-text mb-0">2 Years' Exp</p>
                  <p className="card-text text-muted pb-2">
                    Science Background
                  </p>
                </div>
                <button className="btn  apply-btn ">
                  <a className=" appply" href="Contact">
                    Apply Now
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>

        <nav>
          <ul className="pagination mt-3">
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                4
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                5
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                6
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <Footer/>
    </div>
  );
}

export default Carrers; 