import React from 'react';
import img1 from "../assets/tube.png.png";
import Footer from '../Footer/Footer';

const Division1 = () => {
  return (
    <div>
      <div className="container mt-5 pt-5 mb-5">
        <div className="row  mt-5 pt-5justify-content-center">
          <div className="col-md-6 text-center mt-5">
            <img
              src={img1}
              alt="Product Image"
              className="img-fluid custom-image-class"
            />
          </div>

          <div className="col-md-6 mt-5">
            <h2 className="product-title">HOLYTEL - CH</h2>
            <p className="fw-bold  mt-3">
              Telmisartan 40 mg + Chlorthalidone 12.5 mg Tablets
            </p>
            <p>For better control and protection</p>
            <ul className="product-details">
              <li>Lower risk of complications with dual combination</li>
              <li>Preferred diuretic in combination with antihypertensive</li>
              <li>Better therapeutic efficacy than hydrochlorothiazide</li>
              <li>
                Reduces cardio vascular risk with increase in quality of life of
                post MI life
              </li>
            </ul>
          </div>
        </div>

        <div className="container mt-5">
          <div className="text-center mb-4">
            <h3 className="product-title text-center ">Range Products</h3>
          </div>
          <div className="row text-start justify-content-center">
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                Prelizia-M
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-40
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-CH
              </a>
            </div>

            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-H
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-MT25/50
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-TRIO
              </a>
            </div>

            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-H
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-MT25/50
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-TRIO
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                Prelizia-M
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-40
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-CH
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                Prelizia-M
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-40
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-CH
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                Prelizia-M
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-40
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-CH
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-CH
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                Prelizia-M
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-CH
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                Prelizia-M
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                Prelizia-M
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-40
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-CH
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                Prelizia-M
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-40
              </a>
            </div>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-2">
              <a href="#" className="btn product-btn">
                HOLYTEL-CH
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default Division1;