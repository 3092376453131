import React from 'react';
import image5 from "../assets/Group 1925.png";
import img11 from "../assets/Group 258476.png.png";
import "../Blog/Blog.css";
import img12 from "../assets/blogs-png.jpeg";
import Footer from '../Footer/Footer';


const Blogs = () => {
  return (
    <div>
      <section className="about-sectionm  mt-5  ">
        <img src={image5} alt="Icon Left " className="icon-left" />
        <div className="container mt-5 pt-5">
          <h1 className="text-center">Blog</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style={{ color: "white" }}
              >
                Blog
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ color: "white" }}
              >
                Blogs Details
              </li>
            </ol>
          </nav>
        </div>
        <img src={img11} alt="Icon Right" className="icon-right" />
      </section>
      
      <div className="container">
        <div className="col-lg-12">
          <h3 className="pro-tilltle mt-5 mb-3">
            Lorem Ipsum is simply dummy text <br />
            of the printing and typesetting
            <br />
            industry.
          </h3>
        </div>
        <div className="col-lg-12 mb-3">
          <img className="llog-img" src={img12}></img>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default Blogs;