import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Navbar/Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import image1 from "../assets/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <div>
      <div
        className="top-bar text-light d-flex fixed-top justify-content-between p-2 pb-3"
        style={{ backgroundColor: "#00A0E3" }}
      >
        <div className="d-flex align-items-center mx-2 mx-md-5">
          <div className="icon-wrapper-1 mx-1">
            <FacebookIcon />
          </div>
          <div className="icon-wrapper-1 mx-1">
            <TwitterIcon />
          </div>
          <div className="icon-wrapper-1 mx-1">
            <LinkedInIcon />
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row align-items-center">
          <span
            className="me-md-3 d-flex align-items-center"
            style={{ color: "white" }}
          >
            <LocalPhoneIcon className="icon-wrapper-1 mx-1" /> +91 98986 56982
          </span>
          <span
            className="d-flex align-items-center"
            style={{ color: "white" }}
          >
            <EmailIcon className="icon-wrapper-1 mx-1" />
            anaxlifescience@gmail.com
          </span>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg bg-light fixed-top mt-5">
        <div className="container-fluid">
          <Link to="/Home" id="img">
            <img className="logo-img" src={image1} width="200px" alt="Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link "
                  onClick={handleNavCollapse}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/About"
                  className="nav-link"
                  onClick={handleNavCollapse}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item dropdown" id="myDropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  Division
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <Link
                      to="/Neuro"
                      className="dropdown-item"
                      onClick={handleNavCollapse}
                    >
                      Neuro
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Diahop"
                      className="dropdown-item"
                      onClick={handleNavCollapse}
                    >
                      Diahop
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Gynec"
                      className="dropdown-item"
                      onClick={handleNavCollapse}
                    >
                      Gynec
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Ortho"
                      className="dropdown-item"
                      onClick={handleNavCollapse}
                    >
                      Ortho
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/URO"
                      className="dropdown-item"
                      onClick={handleNavCollapse}
                    >
                      URO
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  to="/Gallary"
                  className="nav-link"
                  onClick={handleNavCollapse}
                >
                  Gallery
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/Blog"
                  className="nav-link"
                  onClick={handleNavCollapse}
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="./Carrers"
                  className="nav-link"
                  onClick={handleNavCollapse}
                >
                  Career
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/Contact"
                  className="nav-link"
                  onClick={handleNavCollapse}
                >
                  Contact Us
                </Link>
              </li>
                  <div class="serch-box">
        <input type="text" class="searchText" placeholder="Type to search"/>
        <a href="#" class="serchbtn">
            <SearchIcon style={{color:"white", marginLeft:"-3px"}}/>
        </a>
    </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
