import React from 'react';
import "../Footer/Footer.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import image31 from "../assets/logo.png";

const Footer = () => {
  return (
    <div>
      <footer className="custom-footer">
        <div className="container py-1 pt-5">
          <div className="row">
            <div className="col-md-4">
              <img
                src={image31}
                alt="Anax Lifescience Pvt Ltd."
                className="mb-3 w-50"
              />
              <p style={{ fontSize: "14px" }}>
                Anax Lifescience, is a pharmaceutical company established in
                2010 & Committed to improve the quality of life of people
                suffering from chronic diseases.
              </p>
              <div className="social-icons">
                <a href="#" className="text-light me-3">
                  <div className="icon-wrapper">
                    <TwitterIcon />
                  </div>
                </a>
                <a href="#" className="text-light me-3">
                  <div className="icon-wrapper">
                    <FacebookOutlinedIcon />
                  </div>
                </a>
                <a href="#" className="text-light">
                  <div className="icon-wrapper">
                    <LinkedInIcon />
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-2">
              <h5>Quick Link</h5>
              <ul className="list-unstyled">
                <li>
                  <a
                    href="/"
                    className="text-light "
                    style={{ fontSize: "14px" }}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="About"
                    className="text-light"
                    style={{ fontSize: "14px" }}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="Gallery"
                    className="text-light "
                    style={{ fontSize: "14px" }}
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <a
                    href="Blog"
                    className="text-light "
                    style={{ fontSize: "14px" }}
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="Career"
                    className="text-light"
                    style={{ fontSize: "14px" }}
                  >
                    Career
                  </a>
                </li>
                <li>
                  <a
                    href="Contact"
                    className="text-light"
                    style={{ fontSize: "14px" }}
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-light"
                    style={{ fontSize: "14px" }}
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-3">
              <h5>Product Segment</h5>
              <ul className="list-unstyled">
                <li style={{ fontSize: "14px" }}>Dyslipidemia Management</li>
                <li style={{ fontSize: "14px" }}>Iron Supplementation</li>
                <li style={{ fontSize: "14px" }}>Diabetic Management</li>
                <li style={{ fontSize: "14px" }}>Migraine Management</li>
                <li style={{ fontSize: "14px" }}>Stroke Management</li>
              </ul>
            </div>

            <div className="col-md-3">
              <div className="d-flex">
                <p>
                  <strong>LOCATION:</strong>
                </p>
                <p className="  mx-3" style={{ fontSize: "14px" }}>
                  407, Swarnim The Business Hub, Opp. SMS Medical College &
                  Hospital, Visat-Tapovan Highway, Motera Ahmedabad, Gujarat
                  INDIA - 380005
                </p>
              </div>
              <div className="d-flex ">
                <p>
                  <strong>Contact:</strong>
                </p>
                <p className=" mx-3" style={{ fontSize: "14px" }}>
                  +91 82380 67107
                  <br />
                  +079 35332746
                </p>
              </div>
              <div className="d-flex">
                <p>
                  <strong>EMAIL:</strong>
                </p>
                <p>
                  <a
                    href="mailto:info@anaxlifescience.in"
                    className="text-light  mx-4"
                    style={{ fontSize: "14px" }}
                  >
                    info@anaxlifescience.in
                  </a>
                </p>
              </div>
            </div>

            <hr className="mt-5" />
            <div className="d-flex justify-content-between">
              <p style={{ fontSize: "14px" }}>
                © Copyright 2024. All Rights Reserved.
              </p>
              <p style={{ fontSize: "14px" }}>
                Design And Developed by Srashtasoft.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;