import React from 'react';
import "../About/About.css";
import image5 from "../assets/Group 1925.png";
import img11 from "../assets/Group 258476.png.png";
import img7 from "../assets/Rectangle.png.png";
import CheckIcon from "@mui/icons-material/Check";
import img8 from "../assets/Rectangle 36.png";
import img9 from "../assets/Rectangle 37.png";
import image8 from "../assets/d39bf3c766eaadbc9817f9b550f9890b.png";
import image9 from "../assets/1ef11ed773a9473ebaa4a86bc36e49ab.png";
import image10 from "../assets/7003a4c2e487a41cf774f0400064f171.png";
import img12 from "../assets/card-1.png.png";
import img13 from "../assets/card-2.png.png";
import img14 from "../assets/card-3.png.png";
import Footer from "../Footer/Footer"

const About = () => {
    const iconStyle = {
      backgroundColor: "#E31E24",
      color: "white",
      borderRadius: "50%",
      padding: "5px",
      marginRight: "10px",
    };
  return (
    <>
      <div>
        <section className="about-sectionm  mt-5  ">
          <img src={image5} alt="Icon Left " className="icon-left" />
          <div className="container mt-5 pt-5">
            <h1 className="text-center">About Us</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "white" }}
                >
                About Us
                </li>
              </ol>
            </nav>
          </div>
          <img src={img11} alt="Icon Right" className="icon-right" />
        </section>
        <div className="container-fluid my-5 mt-5">
          <div className="row">
            <div className="col-md-6 who-we-are mt-5 px-5">
              <div className="d-flex align-items-center mb-4 mt-5 pt-5">
                <div className="red-bg"></div>
                <h2 className="who-are mx-4">Who We Are</h2>
                <img className="reclengale position-absolute" src={img7}></img>
              </div>
              <h3 className=" fw-bold Lifescience mx-4">Anax Lifescience</h3>

              <ul className="mt-5" style={{ paddingLeft: 0 }}>
                <li
                  style={{
                    listStyle: "none",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <CheckIcon style={iconStyle} />
                  </div>
                  <div className="anax">
                    Anax Lifescience, is a pharmaceutical company established in
                    2010 & committed to improve the quality of the life of
                    people suffering from chronic diseases.
                  </div>
                </li>
                <li
                  style={{
                    listStyle: "none",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <CheckIcon style={iconStyle} />
                  </div>
                  <div className="anax">
                    For this purpose Anax is engaged in the marketing and sale
                    of pharmaceutical products across India.
                  </div>
                </li>
                <li
                  style={{
                    listStyle: "none",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <CheckIcon style={iconStyle} />
                  </div>
                  <div className="anax">
                    Anax is a highly professional and performance-driven
                    organization that aims to provide the highest quality
                    healthcare products for doctors and patients, whilst
                    maintaining high ethical standards in business operations.
                  </div>
                </li>
                <li
                  style={{
                    listStyle: "none",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <CheckIcon style={iconStyle} />
                  </div>
                  <div className="anax">
                    Anax is a highly professional and performance-driven
                    organization that aims to provide the highest quality
                    healthcare products for doctors and patients, whilst
                    maintaining high ethical standards in business operations.
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-6 d-flex justify-content-center align-items-center mt-5">
              <section class="about-section mt-5 mx-2">
                <div class="container-fluid">
                  <div class="col-md-12 about-image">
                    <img className="famiiy" src={img8} alt="Family" />
                  </div>
                  <div class="row align-items-center">
                    <img className="shape-img" src={img9}></img>
                    <div class=" about-text">
                      <h2>About Us</h2>
                      <p>
                        Anax Lifescience is a<br />
                        pharmaceutical company
                        <br />
                        established in 2010. <br />
                        Committed to improving
                        <br />
                        the quality of life of
                        <br />
                        people suffering from
                        <br /> chronic diseases.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <section className="py-5 mt-0">
          <div className="container-fluid choose-a">
            <img className="logo-2" src={image5}></img>
            <h2
              className="text-center mb-5 fs-1 fw-bold pt-5 "
              style={{ color: "white" }}
            >
              Why Choose Us
            </h2>
            <div className="row justify-content-center d-flex">
              <div className="col-md-4">
                <div className="card h-100">
                  <img
                    src={image8}
                    className="card-img-top"
                    alt="The Culture"
                  />
                  <div className="card-body">
                    <h5 className="card-title">The Culture</h5>
                    <p className="card-text">
                      We have a transparent and dynamic work culture which
                      provides flexibility, a sense of entrepreneurship that
                      encourages people to experiment and further their careers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card h-100">
                  <img
                    src={image9}
                    className="card-img-top"
                    alt="Quality 100%"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Quality 100%</h5>
                    <p className="card-text">
                      Anax is highly professional and performance-driven
                      organization that aims to provide the highest quality
                      healthcare products for doctors and patients, whilst
                      maintaining high ethical standards in business operations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card h-100">
                  <img
                    src={image10}
                    className="card-img-top"
                    alt="Innovative Solutions"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Innovative Solutions</h5>
                    <p className="card-text">
                      Anax is highly professional and performance-driven
                      organization that aims to provide the highest quality
                      healthcare products for doctors and patients, whilst
                      maintaining high ethical standards in business operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          class="container  mt-5 my-5"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div class="row g-4 pt-5 pb-5 vison-card">
            <div class="col-md-4  col-sm-12">
              <div class="card vision-card h-100">
                <img src={img12} class="card-img-top" alt="Vision Image" />
                <div class="card-body">
                  <h5 class="card-title">Our Vision</h5>
                  <p class="card-text">
                    To lead the path towards a healthier world, we will strive
                    to become the most admired company by doctors, patients,
                    employees, and all channel partners.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="card mission-card h-100">
                <img src={img13} class="card-img-top" alt="Mission Image" />
                <div class="card-body">
                  <h5 class="card-title">Our Mission</h5>
                  <p class="card-text">
                    "To become a world-class pharmaceutical company integrated
                    in all therapy segments with dominant brand leadership."
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4  col-sm-12">
              <div class="card values-card h-100">
                <img src={img14} class="card-img-top" alt="Values Image" />
                <div class="card-body">
                  <h5 class="card-title">Our Values</h5>
                  <p class="card-text">
                    Our core commitment is towards improving healthcare and
                    creating value for all our stakeholders including patients,
                    doctors, regulators, employees, and business partners.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="col-lg-12">
              <p style={{ fontSize: "14px" }}>
                We will be committed to patients and doctors as users of our
                products; we will strive to do our best at every step of the
                chain by providing best of the products and services. We will
                make ongoing efforts to innovate with our products to bring best
                of clinical practices in health care industry to our customers.
              </p>
              <p style={{ fontSize: "14px" }}>
                We will conduct ourselves with high integrity to ensure respect
                of our colleagues and we will strive to provide best of
                compensation and career opportunities to our colleagues.
              </p>
              <p style={{ fontSize: "14px" }}>
                ANAX is one strong family today from diverse origins, cultures
                and academic stream. By bringing together the best abilities,
                experience and knowledge all on a single platform- ANAX has
                achieved remarkable results.
              </p>
              <p className="pb-5" style={{ fontSize: "14px" }}>
                At HO, will make ongoing efforts to innovate with our products
                to bring best of clinical advantage in health care industry to
                our customers
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default About;