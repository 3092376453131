import React from "react";
import "../App.css";
import img1 from "../assets/Mask group.png";
import img2 from "../assets/a.jpg";
import img3 from "../assets/0a67d9a2875a7b7c436fbfd87e4e5158.jpg";
import img4 from "../assets/f2ebd10ca1805dce8ab90eca379d150e.png";
import image5 from "../assets/icon.png";
import img7 from "../assets/Rectangle.png.png";
import img6 from "../assets/c7c50c4dba04e1a2bcef760846b01db4.png";
import CheckIcon from "@mui/icons-material/Check";
import img8 from "../assets/d39bf3c766eaadbc9817f9b550f9890b.png";
import img9 from "../assets/1ef11ed773a9473ebaa4a86bc36e49ab.png";
import img10 from "../assets/7003a4c2e487a41cf774f0400064f171.png";
import img11 from "../assets/Group 258476.png.png";
import img12 from "../assets/Group 258490.png";
import img13 from "../assets/Group 258476.png";
import img14 from "../assets/Group 258488.png";
import img15 from "../assets/Group 258489.png";
import img16 from "../assets/Group 258487.png";
import img17 from "../assets/365d41aab533c101f0ba9acb0901451b.png";
import img18 from "../assets/841759b7469b5ed7a3035638dae34e96.png";
import img19 from "../assets/d00692d6e6fa5d1e3f509e84c340e7b1.png";
import img20 from "../assets/6ac41db4f1bc040cb4c3e0f1a60ef8bf.png";
import img21 from "../assets/64f847b73faed19972d0554af9fe043b.png";
import img22 from "../assets/medical.jpg.jpeg";
import img23 from "../assets/d35db12756428b4754ba86528fd167ce.png";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Footer from "../Footer/Footer";

const Home2 = () => {
  const iconStyle = {
    backgroundColor: "#E31E24",
    color: "white",
    borderRadius: "50%",
    padding: "5px",
    marginRight: "10px",
  };
  return (
    <div>
      <div className="mt-5 pt-5 slider ">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators-1">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={img1}
                className="d-block w-100 img-height"
                alt="Slide 1"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img2}
                className="d-block w-100 img-height"
                alt="Slide 2"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img3}
                className="d-block w-100 img-height"
                alt="Slide 3"
              />
            </div>
            <div className="carousel-item">
              <img
                src={img4}
                className="d-block w-100 img-height"
                alt="Slide 4"
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div className="Health">
          <h1 className="fw-bold Provide mx-4">
            We Provide
            <br />
            Total Health
            <br /> Care Solution
          </h1>
          <button className="pt-2 pb-2 px-5 py-5 mt-3 mx-4 Read">
            Read More
          </button>
        </div>
        <div className="img-icon">
          <img className="icon-img" src={image5}></img>
        </div>
      </div>

      <div className="container my-5 mt-5">
        <div className="row">
          <div className="col-md-6 who-we-are mt-5">
            <div className="d-flex align-items-center mb-4 mt-5 pt-5">
              <div className="red-bg"></div>
              <h2 className="who-are mx-4">Who We Are</h2>
              <img className="reclengale position-absolute" src={img7}></img>
            </div>
            <h3 className=" fw-bold Lifescience mx-4">Anax Lifescience</h3>

            <ul className="mt-5" style={{ paddingLeft: 0 }}>
              <li
                style={{
                  listStyle: "none",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <CheckIcon style={iconStyle} />
                </div>
                <div className="anax">
                  Anax Lifescience, is a pharmaceutical company established in
                  2010 & committed to improve the quality of the life of people
                  suffering from chronic diseases.
                </div>
              </li>
              <li
                style={{
                  listStyle: "none",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <CheckIcon style={iconStyle} />
                </div>
                <div className="anax">
                  For this purpose Anax is engaged in the marketing and sale of
                  pharmaceutical products across India.
                </div>
              </li>
              <li
                style={{
                  listStyle: "none",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <CheckIcon style={iconStyle} />
                </div>
                <div className="anax">
                  Anax is a highly professional and performance-driven
                  organization that aims to provide the highest quality
                  healthcare products for doctors and patients, whilst
                  maintaining high ethical standards in business operations.
                </div>
              </li>
              <li
                style={{
                  listStyle: "none",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <CheckIcon style={iconStyle} />
                </div>
                <div className="anax">
                  Anax is a highly professional and performance-driven
                  organization that aims to provide the highest quality
                  healthcare products for doctors and patients, whilst
                  maintaining high ethical standards in business operations.
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-6 d-flex justify-content-center align-items-center mt-5">
            <div class="triangle-bg">
              <img
                className=" w-100"
                style={{ height: "455px" }}
                src={img6}
                alt="Icon 1"
              />
            </div>
          </div>
        </div>
      </div>

      <section className="py-5">
        <div className="container-fluid choose-a">
          <img className="logo-2" src={image5}></img>
          <h2
            className="text-center mb-5 fs-1 fw-bold pt-5 "
            style={{ color: "white" }}
          >
            Why Choose Us
          </h2>
          <div className="row justify-content-center d-flex">
            <div className="col-md-4">
              <div className="card h-100">
                <img src={img8} className="card-img-top" alt="The Culture" />
                <div className="card-body">
                  <h5 className="card-title">The Culture</h5>
                  <p className="card-text">
                    We have a transparent and dynamic work culture which
                    provides flexibility, a sense of entrepreneurship that
                    encourages people to experiment and further their careers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card h-100">
                <img src={img9} className="card-img-top" alt="Quality 100%" />
                <div className="card-body">
                  <h5 className="card-title">Quality 100%</h5>
                  <p className="card-text">
                    Anax is highly professional and performance-driven
                    organization that aims to provide the highest quality
                    healthcare products for doctors and patients, whilst
                    maintaining high ethical standards in business operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card h-100">
                <img
                  src={img10}
                  className="card-img-top"
                  alt="Innovative Solutions"
                />
                <div className="card-body">
                  <h5 className="card-title">Innovative Solutions</h5>
                  <p className="card-text">
                    Anax is highly professional and performance-driven
                    organization that aims to provide the highest quality
                    healthcare products for doctors and patients, whilst
                    maintaining high ethical standards in business operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section0-2">
        <div class="container my-5 mt-5">
          <div class="row mt-5 pt-5">
            <div class="col-lg-12 col-md-6 who-we-are mt-5">
              <div class="d-flex align-items-center mb-4 ">
                <div class="red-bg"></div>
                <h2 className="who-are-1 mx-5 mt-5">Our Segment</h2>
                <img
                  className="reclengale position-absolute  pt-5"
                  src={img7}
                ></img>
                <img
                  className="reclengale-1  position-absolute"
                  src={img11}
                ></img>
              </div>
              <h3 className=" fw-bold Lifescience-1 mx-5">
                True Healthcare For Your Family!
              </h3>
            </div>
          </div>

          <div
            id="sectionCarousel"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#sectionCarousel"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#sectionCarousel"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#sectionCarousel"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#sectionCarousel"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
            </div>
            <div class="carousel-inner mt-5 pt-4">
              <div class="carousel-item active">
                <div class="row carousel-1  justify-content-center mb-5">
                  <div class="col-md-4 flex-section">
                    <img src={img12} alt="Neuro" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Neuro</h2>
                    <button class="btn  more">Read more</button>
                  </div>

                  <div class="col-md-4 flex-section">
                    <img src={img13} alt="Gynec" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Gynec</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img14} alt="Ortho" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Ortho</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img16} alt="Diahop" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Diahop</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img13} alt="Gynec" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Gynec</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img14} alt="Ortho" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Ortho</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row carousel-1  justify-content-center">
                  <div class="col-md-4 flex-section">
                    <img src={img16} alt="Diahop" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Diahop</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img13} alt="Gynec" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Gynec</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img14} alt="Ortho" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Ortho</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img16} alt="Diahop" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Diahop</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img15} alt="Pedia" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Pedia</h2>
                    <button class="btn more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img12} alt="Neuro" />
                    <h2 className="fs-4 mt-2 mb-3 text-center"> Neuro</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row carousel-1  justify-content-center">
                  <div class="col-md-4 flex-section">
                    <img src={img13} alt="Gynec" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Gynec</h2>
                    <button class="btn more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img14} alt="Ortho" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Ortho</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img16} alt="Diahop" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Diahop</h2>
                    <button class="btn more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img13} alt="Gynec" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Gynec</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img14} alt="Ortho" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Ortho</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img16} alt="Diahop" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Diahop</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row carousel-1  justify-content-center">
                  <div class="col-md-4 flex-section">
                    <img src={img15} alt="Pedia" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Pedia</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img12} alt="Neuro" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Neuro</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img13} alt="Gynec" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Gynec</h2>
                    <button class="btn more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img14} alt="Ortho" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Ortho</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img16} alt="Diahop" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Diahop</h2>
                    <button class="btn  more">Read more</button>
                  </div>
                  <div class="col-md-4 flex-section">
                    <img src={img13} alt="Gynec" />
                    <h2 className="fs-4 mt-2 mb-3 text-center">Gynec</h2>
                    <button class="btn more">Read more</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="slider-1 ">
        <h2 className="who-are-1 mx-5 mt-3 mb-4 text-center">Our Partners</h2>
        <h3 className=" fw-bold Lifescience-1 mx-5 mb-5 text-center">
          Our Brands
        </h3>
        <div class="slide-track mb-5">
          <div class="slide-1">
            <img src={img17} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img18} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img19} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img20} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img17} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img18} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img19} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img20} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img17} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img18} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img19} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img20} height="100" width="250" alt="" />
          </div>
          <div class="slide-1">
            <img src={img17} height="100" width="250" alt="" />
          </div>
        </div>
      </div>

      <div className="container mt-3 ">
        <div className="d-flex align-items-center mb-4 ">
          <div className="red-bg"></div>
          <h2 className="who-are-1 mx-5 mt-5 pt-5">Our Segment</h2>
          <img
            className="reclengale position-absolute mt-5 pt-5"
            src={img7}
          ></img>
        </div>
        <h3 className=" fw-bold Lifescience-1 mx-5">Our Latest Blogs & News</h3>
        <div className="row mt-5 pt-4 mb-5">
          <div className="col-md-4">
            <div className="card ">
              <img src={img21} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="Blogs" class="btn more px-4">
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img src={img22} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="Blogs" class="btn more px-4">
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img src={img23} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="Blogs" class="btn more px-4">
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home2;
