import React from 'react';
import image5 from "../assets/Group 1925.png";
import img11 from "../assets/Group 258476.png.png";
import Footer from "../Footer/Footer";
import "../Contact/Contact.css";


const Contact = () => {
  return (
    <div>
      <section className="about-sectionm  mt-5  ">
        <img src={image5} alt="Icon Left " className="icon-left" />
        <div className="container mt-5 pt-5">
          <h1 className="text-center">Contact</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ color: "white" }}
              >
                Contact
              </li>
            </ol>
          </nav>
        </div>
        <img src={img11} alt="Icon Right" className="icon-right" />
      </section>

      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10 mx-auto">
            <div className="form-container">
              <div className="form-header">
                <h3>Send Us Your Detail</h3>
              </div>
              <h5 class="form-title pb-4 pt-3 text-center">
                Deputy Manager - Production
              </h5>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Qualification"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <textarea
                        class="form-control"
                        placeholder="Comment"
                        rows="2"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <select class="form-select">
                        <option selected>Experience in years</option>
                        <option value="1">1 Year</option>
                        <option value="2">2 Years</option>
                        <option value="3">3 Years</option>
                        <option value="4">4+ Years</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="file" class="form-control" />
                      <p class="upload-cv-label">
                        Doc / PDF (File Will Accept)
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-cancel me-3"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-apply">
                    Apply Now!
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Contact;