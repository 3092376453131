import React from "react";
import image5 from "../assets/Group 1925.png";
import img11 from "../assets/Group 258476.png.png";
import "../Blog/Blog.css";
import img21 from "../assets/64f847b73faed19972d0554af9fe043b.png";
import img22 from "../assets/medical.jpg.jpeg";
import img23 from "../assets/d35db12756428b4754ba86528fd167ce.png";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Footer from "../Footer/Footer";



const Blog = () => {
  return (
    <div>
      <section className="about-sectionm  mt-5  ">
        <img src={image5} alt="Icon Left " className="icon-left" />
        <div className="container mt-5 pt-5">
          <h1 className="text-center">Blog</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ color: "white" }}
              >
                Blog
              </li>
            </ol>
          </nav>
        </div>
        <img src={img11} alt="Icon Right" className="icon-right" />
      </section>

      <div className="container mt-3 ">
        <h3 className=" fw-bold Lifescience-1 mx-5 text-center mt-5">
          Our Latest Blogs & News
        </h3>
        <div className="row mt-5 pt-4 mb-5">
          <div className="col-md-4">
            <div className="card ">
              <img src={img21} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="#" className="btn more px-4 mb-3">
                  <a
                    href="Blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Read More
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img src={img22} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="#" class="btn more px-4 mb-3">
                  <a
                    href="Blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Read More
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img src={img23} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="#" className="btn more px-4 mb-3">
                  <a
                    href="Blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Read More
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3 ">
        <div className="row mt-5 pt-4 mb-5">
          <div className="col-md-4">
            <div className="card ">
              <img src={img21} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="#" class="btn more px-4 mb-3">
                  <a
                    href="Blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Read More
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img src={img22} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="#" class="btn more px-4 mb-3">
                  <a
                    href="Blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Read More
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img src={img23} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="#" class="btn more px-4 mb-3">
                  <a
                    href="Blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Read More
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3 ">
        <div className="row mt-5 pt-4 mb-5">
          <div className="col-md-4">
            <div className="card ">
              <img src={img21} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="#" class="btn more px-4 mb-3">
                  <a
                    href="Blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Read More
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img src={img22} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="#" class="btn more px-4 mb-3">
                  <a
                    href="Blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Read More
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img src={img23} class="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <PeopleAltIcon className="icon" /> Smith Prajapati |
                    <CalendarMonthIcon className="icon" />
                    03 May 2024
                  </small>
                </p>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
                <button href="#" class="btn more px-4 mb-3">
                  <a
                    href="Blogs"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Read More
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Blog;
