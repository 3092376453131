import React from 'react';
import "../Gallary/Gallary.css";
import image5 from "../assets/Group 1925.png";
import img11 from "../assets/Group 258476.png.png";
import img12 from "../assets/05641d6716a14ed6460dba00340e2f22.png";
import img13 from "../assets/15f6e5d4600bc637d2bb649af25096d6.png";
import img14 from "../assets/87fe68a5683e18535676303651106fc0.png";
import img15 from "../assets/7c8f169a9f95da3891200f55c744f006.png";
import img16 from "../assets/728ce876bbec1d9e772d629f6e7601ee.png";
import img17 from "../assets/e1bfbe2f1f243405f737294dd73999d4.png";
import img18 from "../assets/b4ebd99e3d14aa02c847da9eb0a86dc0.png";
import img19 from "../assets/534a66e965c616295b2d8a1929331b0b.png";
import img20 from "../assets/216741217d8959ede6c34b818b0274f0.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Footer from '../Footer/Footer';


const Gallary = () => {
  return (
    <div>
      <section className="about-sectionm  mt-5  ">
        <img src={image5} alt="Icon Left " className="icon-left" />
        <div className="container mt-5 pt-5">
          <h1 className="text-center">Gallary</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ color: "white" }}
              >
                Gallary
              </li>
            </ol>
          </nav>
        </div>
        <img src={img11} alt="Icon Right" className="icon-right" />
      </section>

      <div className="container my-4 mt-5 mb-5">
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <img
                src={img12}
                className="card-img-top"
                alt="Awards Ceremonies"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    05-07-2023
                  </small>
                </p>
                <h5 className="card-title">Awards Ceremonies</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img
                src={img13}
                className="card-img-top"
                alt="Business Seminars"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    06-07-2023
                  </small>
                </p>
                <h5 className="card-title">Business Seminars</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img src={img14} className="card-img-top" alt="Conferences" />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    07-07-2023
                  </small>
                </p>
                <h5 className="card-title">Conferences</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img
                src={img15}
                className="card-img-top"
                alt="Awards Ceremonies"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    08-07-2023
                  </small>
                </p>
                <h5 className="card-title">Awards Ceremonies</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img
                src={img16}
                className="card-img-top"
                alt="Business Seminars"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    09-07-2023
                  </small>
                </p>
                <h5 className="card-title">Business Seminars</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img
                src={img17}
                className="card-img-top"
                alt="Cricket Tournament"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    10-07-2023
                  </small>
                </p>
                <h5 className="card-title">Cricket Tournament</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img
                src={img18}
                className="card-img-top"
                alt="Birthday Celebrations"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    11-07-2023
                  </small>
                </p>
                <h5 className="card-title">Birthday Celebrations</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img src={img19} className="card-img-top" alt="Awards Ceremony" />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    12-07-2023
                  </small>
                </p>
                <h5 className="card-title">Awards Ceremony</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img src={img20} className="card-img-top" alt="Conferences" />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    13-07-2023
                  </small>
                </p>
                <h5 className="card-title">Conferences</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img
                src={img12}
                className="card-img-top"
                alt="Awards Ceremonies"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    14-07-2023
                  </small>
                </p>
                <h5 className="card-title">Awards Ceremonies</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img
                src={img17}
                className="card-img-top"
                alt="Business Seminars"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    15-07-2023
                  </small>
                </p>
                <h5 className="card-title">Business Seminars</h5>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100">
              <img
                src={img19}
                className="card-img-top"
                alt="Awards Ceremonies"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-muted">
                    <CalendarMonthIcon className="icon" />
                    16-07-2023
                  </small>
                </p>
                <h5 className="card-title">Awards Ceremonies</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Gallary;