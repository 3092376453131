import React from "react";
import image5 from "../assets/Group 1925.png";
import img11 from "../assets/Group 258476.png.png";
import "../Division/Division.css";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Footer from "../Footer/Footer";

const Gynec = () => {
  return (
    <div>
      <section className="about-sectionm mt-5">
        <img src={image5} alt="Icon Left" className="icon-left" />
        <div className="container mt-5 pt-5">
          <h1 className="text-center">Division</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ color: "white" }}
              >
                Division
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                style={{ color: "white" }}
              >
                Gynec
              </li>
            </ol>
          </nav>
        </div>
        <img src={img11} alt="Icon Right" className="icon-right" />
      </section>

      <div className="container mt-5">
        <ul
          className="nav nav-tabs justify-content-center mb-4"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="hypertension-tab"
              data-bs-toggle="tab"
              data-bs-target="#hypertension"
              type="button"
              role="tab"
              aria-controls="hypertension"
              aria-selected="true"
            >
              Hypertension Management
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="dyslipidemia-tab"
              data-bs-toggle="tab"
              data-bs-target="#dyslipidemia"
              type="button"
              role="tab"
              aria-controls="dyslipidemia"
              aria-selected="false"
            >
              Dyslipidemia Management
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="diabetic-tab"
              data-bs-toggle="tab"
              data-bs-target="#diabetic"
              type="button"
              role="tab"
              aria-controls="diabetic"
              aria-selected="false"
            >
              Diabetic Management
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="neuropathy-tab"
              data-bs-toggle="tab"
              data-bs-target="#neuropathy"
              type="button"
              role="tab"
              aria-controls="neuropathy"
              aria-selected="false"
            >
              Neuropathy Management
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="dyslipidemia-tab"
              data-bs-toggle="tab"
              data-bs-target="#dyslipidemia"
              type="button"
              role="tab"
              aria-controls="dyslipidemia"
              aria-selected="false"
            >
              Dyslipidemia Management
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="diabetic-tab"
              data-bs-toggle="tab"
              data-bs-target="#diabetic"
              type="button"
              role="tab"
              aria-controls="diabetic"
              aria-selected="false"
            >
              Diabetic Management
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="neuropathy-tab"
              data-bs-toggle="tab"
              data-bs-target="#neuropathy"
              type="button"
              role="tab"
              aria-controls="neuropathy"
              aria-selected="false"
            >
              Neuropathy Management
            </button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="hypertension"
            role="tabpanel"
            aria-labelledby="hypertension-tab"
          >
            <h3 className="text-center mb-4 product-tittle">
              Hypertension Management
            </h3>
            <div className="table-responsive">
              <div className="search-bar row align-items-center mb-3 pt-5 pb-2">
                <div className="col-md-6 col-lg-8">
                  <div className="input-group">
                    <span className="input-group-text">
                      <SearchIcon />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 text-end">
                  <div className="input-group">
                    <select className="form-select">
                      <option selected>Show 100</option>
                      <option value="50">50</option>
                      <option value="20">20</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <thead className="table-primary">
                  <tr>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Sr.No
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Name Of Product
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Content
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Packing
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>02</td>
                    <td>HOLYTEL-AM</td>
                    <td>TELMISARTAN 40MG + AMLODIPINE 5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>HOLYTEL-CH</td>
                    <td>TELMISARTAN 40MG + CHLORTHALIDONE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>HOLYTEL-H</td>
                    <td>TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>TELMISARTAN 40MG + METOPROLOL 50MG TAB</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>
                      TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG + AMLODIPINE
                      5M
                    </td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Pagination Section */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#60;
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#62;
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="dyslipidemia"
            role="tabpanel"
            aria-labelledby="dyslipidemia-tab"
          >
            <h3 className="text-center mb-4 product-tittle">
              Dyslipidemia Management
            </h3>
            <div className="table-responsive">
              <div className="search-bar row align-items-center mb-3 pt-5 pb-2">
                <div className="col-md-6 col-lg-8">
                  <div className="input-group">
                    <span className="input-group-text">
                      <SearchIcon />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 text-end">
                  <div className="input-group">
                    <select className="form-select">
                      <option selected>Show 100</option>
                      <option value="50">50</option>
                      <option value="20">20</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <thead className="table-primary">
                  <tr>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Sr.No
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Name Of Product
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Content
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Packing
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>02</td>
                    <td>HOLYTEL-AM</td>
                    <td>TELMISARTAN 40MG + AMLODIPINE 5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>HOLYTEL-CH</td>
                    <td>TELMISARTAN 40MG + CHLORTHALIDONE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>HOLYTEL-H</td>
                    <td>TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>TELMISARTAN 40MG + METOPROLOL 50MG TAB</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>
                      TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG + AMLODIPINE
                      5M
                    </td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Pagination Section */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#60;
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#62;
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="diabetic"
            role="tabpanel"
            aria-labelledby="diabetic-tab"
          >
            <h3 className="text-center mb-4 product-tittle">
              Diabetic Management
            </h3>
            <div className="table-responsive">
              <div className="search-bar row align-items-center mb-3 pt-5 pb-2">
                <div className="col-md-6 col-lg-8">
                  <div className="input-group">
                    <span className="input-group-text">
                      <SearchIcon />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 text-end">
                  <div className="input-group">
                    <select className="form-select">
                      <option selected>Show 100</option>
                      <option value="50">50</option>
                      <option value="20">20</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <thead className="table-primary">
                  <tr>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Sr.No
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Name Of Product
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Content
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Packing
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>02</td>
                    <td>HOLYTEL-AM</td>
                    <td>TELMISARTAN 40MG + AMLODIPINE 5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>HOLYTEL-CH</td>
                    <td>TELMISARTAN 40MG + CHLORTHALIDONE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>HOLYTEL-H</td>
                    <td>TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>TELMISARTAN 40MG + METOPROLOL 50MG TAB</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>
                      TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG + AMLODIPINE
                      5M
                    </td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Pagination Section */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#60;
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#62;
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="neuropathy"
            role="tabpanel"
            aria-labelledby="neuropathy-tab"
          >
            <h3 className="text-center mb-4 product-tittle">
              Neuropathy Management
            </h3>
            <div className="table-responsive">
              <div className="search-bar row align-items-center mb-3 pt-5 pb-2">
                <div className="col-md-6 col-lg-8">
                  <div className="input-group">
                    <span className="input-group-text">
                      <SearchIcon />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 text-end">
                  <div className="input-group">
                    <select className="form-select">
                      <option selected>Show 100</option>
                      <option value="50">50</option>
                      <option value="20">20</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <thead className="table-primary">
                  <tr>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Sr.No
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Name Of Product
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Content
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Packing
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>02</td>
                    <td>HOLYTEL-AM</td>
                    <td>TELMISARTAN 40MG + AMLODIPINE 5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>HOLYTEL-CH</td>
                    <td>TELMISARTAN 40MG + CHLORTHALIDONE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>HOLYTEL-H</td>
                    <td>TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>TELMISARTAN 40MG + METOPROLOL 50MG TAB</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>
                      TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG + AMLODIPINE
                      5M
                    </td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Pagination Section */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#60;
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#62;
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="dyslipidemia"
            role="tabpanel"
            aria-labelledby="dyslipidemia-tab"
          >
            <h3 className="text-center mb-4 product-tittle">
              Dyslipidemia Management
            </h3>
            <div className="table-responsive">
              <div className="search-bar row align-items-center mb-3 pt-5 pb-2">
                <div className="col-md-6 col-lg-8">
                  <div className="input-group">
                    <span className="input-group-text">
                      <SearchIcon />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 text-end">
                  <div className="input-group">
                    <select className="form-select">
                      <option selected>Show 100</option>
                      <option value="50">50</option>
                      <option value="20">20</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <thead className="table-primary">
                  <tr>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Sr.No
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Name Of Product
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Content
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Packing
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>02</td>
                    <td>HOLYTEL-AM</td>
                    <td>TELMISARTAN 40MG + AMLODIPINE 5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>HOLYTEL-CH</td>
                    <td>TELMISARTAN 40MG + CHLORTHALIDONE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>HOLYTEL-H</td>
                    <td>TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>TELMISARTAN 40MG + METOPROLOL 50MG TAB</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>
                      TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG + AMLODIPINE
                      5M
                    </td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Pagination Section */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#60;
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#62;
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="diabetic"
            role="tabpanel"
            aria-labelledby="diabetic-tab"
          >
            <h3 className="text-center mb-4 product-tittle">
              Diabetic Management
            </h3>
            <div className="table-responsive">
              <div className="search-bar row align-items-center mb-3 pt-5 pb-2">
                <div className="col-md-6 col-lg-8">
                  <div className="input-group">
                    <span className="input-group-text">
                      <SearchIcon />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 text-end">
                  <div className="input-group">
                    <select className="form-select">
                      <option selected>Show 100</option>
                      <option value="50">50</option>
                      <option value="20">20</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <thead className="table-primary">
                  <tr>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Sr.No
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Name Of Product
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Content
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Packing
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>02</td>
                    <td>HOLYTEL-AM</td>
                    <td>TELMISARTAN 40MG + AMLODIPINE 5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>HOLYTEL-CH</td>
                    <td>TELMISARTAN 40MG + CHLORTHALIDONE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>HOLYTEL-H</td>
                    <td>TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>TELMISARTAN 40MG + METOPROLOL 50MG TAB</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>
                      TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG + AMLODIPINE
                      5M
                    </td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Pagination Section */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#60;
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#62;
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="neuropathy"
            role="tabpanel"
            aria-labelledby="neuropathy-tab"
          >
            <h3 className="text-center mb-4 product-tittle">
              Neuropathy Management
            </h3>
            <div className="table-responsive">
              <div className="search-bar row align-items-center mb-3 pt-5 pb-2">
                <div className="col-md-6 col-lg-8">
                  <div className="input-group">
                    <span className="input-group-text">
                      <SearchIcon />
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 text-end">
                  <div className="input-group">
                    <select className="form-select">
                      <option selected>Show 100</option>
                      <option value="50">50</option>
                      <option value="20">20</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-striped">
                <thead className="table-primary">
                  <tr>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Sr.No
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Name Of Product
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Content
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      Packing
                    </th>
                    <th style={{ backgroundColor: "#00A0E3", color: "white" }}>
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>02</td>
                    <td>HOLYTEL-AM</td>
                    <td>TELMISARTAN 40MG + AMLODIPINE 5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>HOLYTEL-CH</td>
                    <td>TELMISARTAN 40MG + CHLORTHALIDONE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>HOLYTEL-H</td>
                    <td>TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>HOLYTEL-40</td>
                    <td>TELMISARTAN 40MG</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>TELMISARTAN 40MG + METOPROLOL 50MG TAB</td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>HOLYTEL-MT25/50</td>
                    <td>
                      TELMISARTAN 40MG + HYDROCHLORTHIAZIDE 12.5MG + AMLODIPINE
                      5M
                    </td>
                    <td>10 TAB</td>
                    <td>
                      <a href="Division1">
                        <VisibilityIcon />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Pagination Section */}
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#60;
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      &#62;
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Gynec;
