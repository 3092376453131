import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Home from "./Home/Home";
import About from "./About/About";
import Home2 from "./Home/Home2";
import Carrers from "./Carrers/Carrers";
import Blog from "./Blog/Blog";
import Contact from "./Contact/Contact";
import Gallary from "./Gallary/Gallary";
import Footer from "./Footer/Footer"; 
import Neuro from "./Division/Neuro";
import Diahop from "./Division/Diahop";
import Gynec from "./Division/Gynec";
import Ortho from "./Division/Ortho";
import URO from "./Division/URO";
import Division1 from "./Division/Division1";
import Blogs from "./Blog/Blogs";
import Gallary2 from "./Gallary/Gallary2";


const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home2 />} />
        <Route path="/About" element={<About />} />
        <Route path="/Carrers" element={<Carrers />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Neuro" element={<Neuro />} />
        <Route path="/Diahop" element={<Diahop />} />
        <Route path="/Gynec" element={<Gynec />} />
        <Route path="/Ortho" element={<Ortho />} />
        <Route path="/URO" element={<URO />} />
        <Route path="/Division1" element={<Division1 />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Gallary" element={<Gallary />} />
        <Route path="/Gallary2" element={<Gallary2 />} />
      </Routes>
    </Router>
  );
};

export default App;
